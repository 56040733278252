<template>
    <div>
        <Datatable 
            ref="datatable"
            :config="datatableConfig"
            :elements="facilities"
            :stripe="true"
            :sst="true"
            :pagination="pagination"
            :globalFilters="[{field: 'type', value:type}]"
            @filter="handleFilter"
            @action="handleAction"
            :addRow="handleAddRow"
        />
    </div>
</template>
<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import FacilitiesMixins from "./facilitiesMixins";

export default {
    name: 'bikepark-form-datatable',
    mixins: [FacilitiesMixins],
    components: {
        Datatable
    },
    data () {
        return {
            type: 'bikepark-trail',
        }
    },
   
    methods: {
        getDataTableConfig() {
            this.datatableConfig = this.prepareDataTableConfig([
                'name', 'code', 'difficulty', 'status', 'zone', 'length', 'actions'
            ])
        },
    },

}
</script>