import i18n from "@/i18n";

export function getResortStatus() 
{
  return [
    { label: i18n.t('snowreport.status.open'), value: 'true' },
    { label: i18n.t('snowreport.status.closed'), value: 'false' },
  ]
}

export function getAvalancheRisk() 
{
  return [
    { label: '-', value: null },
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3},
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ]
}

export function getFormatInSelectSnowTypes(type) 
{
    switch (type) {
        case 'altschnee':       return { label: i18n.t("snowreport.snowtype.old"),            value: 'altschnee'}
        case 'corn_snow':       return { label: i18n.t("snowreport.snowtype.corn"),           value: 'corn_snow'}
        case 'fine_granular':   return { label: i18n.t("snowreport.snowtype.finegranular"),   value: 'fine_granular'}
        case 'frozen_granular': return { label: i18n.t("snowreport.snowtype.frozengranular"), value: 'frozen_granular'}
        case 'fruhlingsschnee': return { label: i18n.t("snowreport.snowtype.spring"),         value: 'fruhlingsschnee'}
        case 'hard_pack':       return { label: i18n.t("snowreport.snowtype.hardpack"),       value: 'hard_pack'}
        case 'hard_spring':     return { label: i18n.t("snowreport.snowtype.hardspring"),     value: 'hard_spring'}
        case 'icy':             return { label: i18n.t("snowreport.snowtype.icy"),            value: 'icy'}
        case 'kunstschnee':     return { label: i18n.t("snowreport.snowtype.artificial"),     value: 'kunstschnee'}
        case 'looses_granular': return { label: i18n.t("snowreport.snowtype.loosesgranular"), value: 'looses_granular'}
        case 'machine_groomed': return { label: i18n.t("snowreport.snowtype.machinegrommed"), value: 'machine_groomed'}
        // case 'nassschnee':      return { label: i18n.t("snowreport.snowtype."), value: 'nassschnee'}
        // case 'natural_snow':    return { label: i18n.t("snowreport.snowtype."), value: 'natural_snow'}
        case 'neuschnee':       return { label: i18n.t("snowreport.snowtype.new"),            value: 'neuschnee'}
        case 'packedpowder':    return { label: i18n.t("snowreport.snowtype.packedpowder"),   value: 'packedpowder'}
        case 'pulverschnee':    return { label: i18n.t("snowreport.snowtype.powder"),         value: 'pulverschnee'}
        case 'powdertreaded':   return { label: i18n.t("snowreport.snowtype.powdertrated"),   value: 'powdertreaded'}
        case 'sulz_harsch':     return { label: i18n.t("snowreport.snowtype.hard"),           value: 'sulz_harsch'}
        case 'wet_granular':    return { label: i18n.t("snowreport.snowtype.wetgranular"),    value: 'wet_granular'}
        case 'wet_packed':      return { label: i18n.t("snowreport.snowtype.wetpacked"),      value: 'wet_packed'}
        case 'wind_blown':      return { label: i18n.t("snowreport.snowtype.windblown"),      value: 'wind_blown'}
        case 'variable_conditions': return { label: i18n.t("snowreport.snowtype.variableconditions"), value: 'variable_conditions'}
        case 'snowy_slopes_with_perfect_ski_conditions': return { label: i18n.t("snowreport.snowtype.perfect"), value: 'snowy_slopes_with_perfect_ski_conditions'}
        // case 'pulverschnee_nassschnee': return { label: i18n.t("snowreport.snowtype."), value: 'pulverschnee_nassschnee'}

        default: return { label: i18n.t('-'), value: null }   
    }
 
}
export function getSnowTypes() 
{
  return [
    { label: i18n.t('-'), value: null },
    { label: i18n.t('snowreport.snowtype.new'), value: 'neuschnee' },
    { label: i18n.t('snowreport.snowtype.spring'), value: 'fruhlingsschnee' },
    { label: i18n.t('snowreport.snowtype.old'), value: 'altschnee' },
    { label: i18n.t('snowreport.snowtype.hard'), value: 'sulz_harsch' },
    { label: i18n.t('snowreport.snowtype.wet'), value: 'nassschnee' },
    { label: i18n.t('snowreport.snowtype.powder'), value: 'pulverschnee' },
    { label: i18n.t('snowreport.snowtype.packedpowder'), value: 'packedpowder' },
    { label: i18n.t('snowreport.snowtype.artificial'), value: 'kunstschnee' },
    { label: i18n.t('snowreport.snowtype.hardpack'), value: 'hard_pack' },
    { label: i18n.t('snowreport.snowtype.loosesgranular'), value: 'looses_granular' },
    { label: i18n.t('snowreport.snowtype.frozengranular'), value: 'frozen_granular' },
    { label: i18n.t('snowreport.snowtype.wetpacked'), value: 'wet_packed' },
    { label: i18n.t('snowreport.snowtype.wetgranular'), value: 'wet_granular' },
    { label: i18n.t('snowreport.snowtype.windblown'), value: 'wind_blown' },
    { label: i18n.t('snowreport.snowtype.corn'), value: 'corn_snow' },
    { label: i18n.t('snowreport.snowtype.icy'), value: 'icy' },
    { label: i18n.t('snowreport.snowtype.variableconditions'), value: 'variable_conditions' },
    { label: i18n.t('snowreport.snowtype.natural'), value: 'natural_snow' },
    { label: i18n.t('snowreport.snowtype.perfect'), value: 'snowy_slopes_with_perfect_ski_conditions' },    
    { label: i18n.t('snowreport.snowtype.machinegrommed'), value: 'machine_groomed' },
    { label: i18n.t('snowreport.snowtype.powdertrated'), value: 'powdertreaded' },
    { label: i18n.t('snowreport.snowtype.finegranular'), value: 'fine_granular' },
  ]
}

export function findTypeOfUnit(key) {
    switch(key) {
      case 'min_snow':
      case 'max_snow':
      case 'mid_snow':
      case 'snowfall_24':
      case 'snowfall_48':
      case 'snowfall_72':
      case 'snowfall_season':
      case 'snowfall_overnight':
      case 'base_snow':
      case 'new_snow':
        return 'depth';

      case 'wind_speed':
        return 'speed';

      case 'skiable_km':
        return 'distance';

      case 'temp_min':
      case 'temp_max':
        return 'temperature';
      
      default:
        return 'any'
    }
  }