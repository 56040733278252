var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row w-full " },
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-3" } },
        [
          _c(
            "b-tab",
            {
              staticClass: "mb-10",
              attrs: {
                title: _vm.$t("snowreport.report.maintitle"),
                active: ""
              }
            },
            [
              _c("SnowReportForm", {
                attrs: {
                  referenceSystem: _vm.resortInfo.reference_system,
                  timezone: _vm.resortInfo.timezone
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.slopestitle") } },
            [
              _c("SlopeFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.liftstitle") } },
            [_c("LiftFormDataTable")],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { title: _vm.$t("snowreport.report.bikeparkslopestitle") }
            },
            [
              _c("BikeparkFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.linkstitle") } },
            [
              _c("LinkFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.itinerariestitle") } },
            [
              _c("ItineraryFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.activitiestitle") } },
            [
              _c("ActivityFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.parkingtitle") } },
            [
              _c("ParkingFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("snowreport.report.roadstitle") } },
            [
              _c("RoadAccesFormDataTable", {
                attrs: {
                  facilityDifficultyReferenceSystem:
                    _vm.resortInfo.slope_difficulty_referencesystem
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }