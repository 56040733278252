var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Datatable", {
        ref: "datatable",
        attrs: {
          config: _vm.datatableConfig,
          elements: _vm.facilities,
          stripe: true,
          sst: true,
          pagination: _vm.pagination,
          globalFilters: [{ field: "type", value: _vm.type }],
          addRow: _vm.handleAddRow
        },
        on: { filter: _vm.handleFilter, action: _vm.handleAction }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }