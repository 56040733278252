var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154" },
    [
      _c("h2", [
        _vm._v(
          _vm._s(_vm.$t("snowreport.title.lastreport")) +
            " " +
            _vm._s(_vm.dateReport)
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.resortstatus")))
            ]),
            _c(
              "vs-select",
              {
                staticClass: "w-full border-grey-light",
                model: {
                  value: _vm.snowReportDataSelected.open,
                  callback: function($$v) {
                    _vm.$set(_vm.snowReportDataSelected, "open", $$v)
                  },
                  expression: "snowReportDataSelected.open"
                }
              },
              _vm._l(_vm.resortStatus, function(status) {
                return _c("vs-select-item", {
                  key: status.value,
                  attrs: { value: status.value, text: status.label }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.avalancherisk")))
            ]),
            _c(
              "vs-select",
              {
                staticClass: "w-full border-grey-light",
                model: {
                  value: _vm.snowReportDataSelected.avalanche_risk,
                  callback: function($$v) {
                    _vm.$set(_vm.snowReportDataSelected, "avalanche_risk", $$v)
                  },
                  expression: "snowReportDataSelected.avalanche_risk"
                }
              },
              _vm._l(_vm.avalancheRisk, function(risk) {
                return _c("vs-select-item", {
                  key: risk.value,
                  attrs: { value: risk.value, text: risk.label }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.openslopes")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0" },
              on: {
                blur: function($event) {
                  return _vm.checkMaxValueNumber(
                    "open_slopes",
                    _vm.snowReportDataSelected.open_slopes,
                    100
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.open_slopes,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "open_slopes", $$v)
                },
                expression: "snowReportDataSelected.open_slopes"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.openlifts")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0" },
              on: {
                blur: function($event) {
                  return _vm.checkMaxValueNumber(
                    "open_lifts",
                    _vm.snowReportDataSelected.open_slopes,
                    100
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.open_lifts,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "open_lifts", $$v)
                },
                expression: "snowReportDataSelected.open_lifts"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.distance")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              model: {
                value: _vm.snowReportDataSelected.skiable_km,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "skiable_km", $$v)
                },
                expression: "snowReportDataSelected.skiable_km"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")(
                    "distance",
                    _vm.referenceSystem
                  )
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.mintemperature")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", step: "0.01" },
              model: {
                value: _vm.snowReportDataSelected.temp_min,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "temp_min", $$v)
                },
                expression: "snowReportDataSelected.temp_min"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")(
                    "temperature",
                    _vm.referenceSystem
                  )
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.maxtemperature")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", step: "0.01" },
              model: {
                value: _vm.snowReportDataSelected.temp_max,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "temp_max", $$v)
                },
                expression: "snowReportDataSelected.temp_max"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")(
                    "temperature",
                    _vm.referenceSystem
                  )
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.windspeed")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "wind_speed",
                    _vm.snowReportDataSelected.wind_speed,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.wind_speed,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "wind_speed", $$v)
                },
                expression: "snowReportDataSelected.wind_speed"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("speed", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.minsnowdepth")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "min_snow",
                    _vm.snowReportDataSelected.min_snow,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.min_snow,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "min_snow", $$v)
                },
                expression: "snowReportDataSelected.min_snow"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.maxsnowdepth")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "max_snow",
                    _vm.snowReportDataSelected.max_snow,
                    _vm.snowReportDataSelected.min_snow
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.max_snow,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "max_snow", $$v)
                },
                expression: "snowReportDataSelected.max_snow"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.midmountainsnowdepth")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "mid_snow",
                    _vm.snowReportDataSelected.mid_snow,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.mid_snow,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "mid_snow", $$v)
                },
                expression: "snowReportDataSelected.mid_snow"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfall24h")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "snowfall_24",
                    _vm.snowReportDataSelected.snowfall_24,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.snowfall_24,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "snowfall_24", $$v)
                },
                expression: "snowReportDataSelected.snowfall_24"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfall48h")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "snowfall_48",
                    _vm.snowReportDataSelected.snowfall_48,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.snowfall_48,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "snowfall_48", $$v)
                },
                expression: "snowReportDataSelected.snowfall_48"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfall72h")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "snowfall_72",
                    _vm.snowReportDataSelected.snowfall_72,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.snowfall_72,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "snowfall_72", $$v)
                },
                expression: "snowReportDataSelected.snowfall_72"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfallseason")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "snowfall_season",
                    _vm.snowReportDataSelected.snowfall_season,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.snowfall_season,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "snowfall_season", $$v)
                },
                expression: "snowReportDataSelected.snowfall_season"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfallovernight")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "snowfall_overnight",
                    _vm.snowReportDataSelected.snowfall_overnight,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.snowfall_overnight,
                callback: function($$v) {
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "snowfall_overnight",
                    $$v
                  )
                },
                expression: "snowReportDataSelected.snowfall_overnight"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.snowfallcondition")))
            ]),
            _vm.isDataLoaded
              ? _c(
                  "vs-select",
                  {
                    staticClass: "w-full border-grey-light",
                    model: {
                      value: _vm.snowReportDataSelected.snow_type.value,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.snowReportDataSelected.snow_type,
                          "value",
                          $$v
                        )
                      },
                      expression: "snowReportDataSelected.snow_type.value"
                    }
                  },
                  _vm._l(_vm.snowTypes, function(snowType) {
                    return _c("vs-select-item", {
                      key: snowType.value,
                      attrs: { value: snowType.value, text: snowType.label }
                    })
                  }),
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.basesnow")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "base_snow",
                    _vm.snowReportDataSelected.base_snow,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.base_snow,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "base_snow", $$v)
                },
                expression: "snowReportDataSelected.base_snow"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("height", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.newsnow")))
            ]),
            _c("vs-input", {
              staticClass: "w-full border-grey-light",
              attrs: { type: "number", min: "0", step: "0.01" },
              on: {
                blur: function($event) {
                  return _vm.checkMinValueNumber(
                    "new_snow",
                    _vm.snowReportDataSelected.new_snow,
                    0
                  )
                }
              },
              model: {
                value: _vm.snowReportDataSelected.new_snow,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "new_snow", $$v)
                },
                expression: "snowReportDataSelected.new_snow"
              }
            }),
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                _vm._s(
                  _vm._f("unit_system_converter")("depth", _vm.referenceSystem)
                )
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.lastsnowfall")))
            ]),
            _c("b-form-datepicker", {
              staticClass: "w-full rounded-lg",
              attrs: {
                "today-button": "",
                "reset-button": "",
                "close-button": "",
                placeholder: "",
                name: "last_snowfall",
                "date-format-options": {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                },
                locale: _vm.applicationLanguage
              },
              model: {
                value: _vm.snowReportDataSelected.last_snowfall,
                callback: function($$v) {
                  _vm.$set(_vm.snowReportDataSelected, "last_snowfall", $$v)
                },
                expression: "snowReportDataSelected.last_snowfall"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.seasonopeningdate")))
            ]),
            _c("b-form-datepicker", {
              staticClass: "w-full rounded-lg",
              attrs: {
                "reset-button": "",
                "close-button": "",
                placeholder: "",
                name: "seasonopeningdate",
                "date-format-options": {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                },
                locale: _vm.applicationLanguage
              },
              model: {
                value: _vm.snowReportDataSelected.season_opening_date,
                callback: function($$v) {
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "season_opening_date",
                    $$v
                  )
                },
                expression: "snowReportDataSelected.season_opening_date"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.seasonclosingdate")))
            ]),
            _c("b-form-datepicker", {
              staticClass: "w-full rounded-lg",
              attrs: {
                "reset-button": "",
                "close-button": "",
                placeholder: "",
                name: "seasonclosingdate",
                "date-format-options": {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                },
                locale: _vm.applicationLanguage
              },
              model: {
                value: _vm.snowReportDataSelected.season_closing_date,
                callback: function($$v) {
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "season_closing_date",
                    $$v
                  )
                },
                expression: "snowReportDataSelected.season_closing_date"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.conditionssummary")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.snowReportDataSelected.conditions_summary,
                  expression: "snowReportDataSelected.conditions_summary"
                }
              ],
              staticClass: "w-full h-32 rounded resize-none border-grey-light",
              domProps: {
                value: _vm.snowReportDataSelected.conditions_summary
              },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "conditions_summary",
                    $event.target.value
                  )
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.anouncement")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.snowReportDataSelected.anouncement,
                  expression: "snowReportDataSelected.anouncement"
                }
              ],
              staticClass: "w-full h-32 rounded resize-none border-grey-light",
              domProps: { value: _vm.snowReportDataSelected.anouncement },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "anouncement",
                    $event.target.value
                  )
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.resortsummary")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.snowReportDataSelected.resortsummary,
                  expression: "snowReportDataSelected.resortsummary"
                }
              ],
              staticClass: "w-full h-32 rounded resize-none border-grey-light",
              domProps: { value: _vm.snowReportDataSelected.resortsummary },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "resortsummary",
                    $event.target.value
                  )
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.weatherreport")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.snowReportDataSelected.weather_report,
                  expression: "snowReportDataSelected.weather_report"
                }
              ],
              staticClass: "w-full h-32 rounded resize-none border-grey-light",
              domProps: { value: _vm.snowReportDataSelected.weather_report },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "weather_report",
                    $event.target.value
                  )
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "w-full vx-row" }, [
        _c(
          "div",
          { staticClass: "w-full vx-col xl:w-1/2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("snowreport.label.openinghours")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.snowReportDataSelected.opening_hours,
                  expression: "snowReportDataSelected.opening_hours"
                }
              ],
              staticClass: "w-full h-32 rounded resize-none border-grey-light",
              domProps: { value: _vm.snowReportDataSelected.opening_hours },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.snowReportDataSelected,
                    "opening_hours",
                    $event.target.value
                  )
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-alert",
        {
          staticClass: "my-5 rounded-lg",
          attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
        },
        [
          _c(
            "ul",
            { staticClass: "list-none" },
            _vm._l(_vm.errorsOnSave, function(errorString, index) {
              return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
            }),
            0
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled rounded-lg w-48 mr-10" },
              on: {
                click: function($event) {
                  return _vm.saveSnowReport()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("snowreport.status.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }