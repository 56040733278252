import axios from '@/axios.js'

const getResortFacilities = async (resortUuid, params) => {
 try{
    const response = await axios.editorapi.get(`/resorts/v3/${resortUuid}/facilities`,{ params });
    return response.data.object
 } catch (error) {
    throw new Error(error)
 }
}

const createResortFacility = async (resortUuid, payload) => {
    return axios.editorapi.post(`/resorts/v3/${resortUuid}/facilities`,payload)
}

const updateResortFacility = async (resortUuid, facilityUuid, payload) => {
    const response = await axios.editorapi.patch(`/resorts/v3/${resortUuid}/facilities/${facilityUuid}`,payload)
    return response.data
}

const deleteResortFacility = async (resortUuid, facilityUuid) => {
    const response =  await axios.editorapi.delete(`/resorts/v3/${resortUuid}/facilities/${facilityUuid}`)
    if(response.status !== 200) {
        return response
    }
    return response.data
}
export default {
    getResortFacilities,
    createResortFacility,
    updateResortFacility,
    deleteResortFacility
}