import axios from '@/axios.js'
export default {
    getSnowReport: async (resortUuid) => {
        try {
            const response = await axios.editorapi.get(`snowreport/v3/${resortUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        } 
    },

    getSnowReportZones: async (resortUuid) => {
        try {
            const response = await axios.editorapi.get(`snowreport/v3/zones/${resortUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        } 
    },

    updateSnowReport: async (resortUuid, payload) => {
        try {
            const response =  await axios.editorapi.patch(`snowreport/v3/${resortUuid}`,payload)
            return response.data
        } catch (error) {
            return new Error(error)
        }
    },

    createSnowReport: async (resortUuid, payload) => {
        try {
            const response =  await axios.editorapi.post(`snowreport/v3/${resortUuid}`,payload)
            return response.data
        } catch (error) {
            return new Error(error)
        }
    },
}