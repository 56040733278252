import axios from '@/axios.js'

export default {
    getZones: async (resortUuid, parameters) => {
        try {
            let params = {params:{...parameters,...{perPage: 50, sort_by: 'ordering', sort_type: 'asc'}}}
            const response = await axios.editorapi.get(`v4/resorts/${resortUuid}/zones`, params)
            return response.data.object

        } catch (error) {
            throw new Error(error)
        }
    },
    createZone: async (resortUuid, zoneUuid, payload) => {
        try {
            const response = await axios.editorapi.post(`v4/resorts/${resortUuid}/zones/${zoneUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    editZone: async (resortUuid, zoneUuid, payload) => {
        try {
            const response = await axios.editorapi.patch(`v4/resorts/${resortUuid}/zones/${zoneUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteZone: async (resortUuid, zoneUuid) => {
        try {
            const response = await axios.editorapi.delete(`v4/resorts/${resortUuid}/zones/${zoneUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    sortZones: async (resortUuid, sortedZones) => {
        try {
            const response = await axios.editorapi.patch(`v4/resorts/${resortUuid}/zones`, { 'sorted_items': sortedZones })
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    }
}
