<template>
    <div class="w-full p-6 bg-white rounded-lg shadow md:w-154 lg:w-154">
      <h2>{{ $t("snowreport.title.lastreport") }} {{ dateReport }}</h2>
      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.resortstatus") }}</LabelForm> 
          <vs-select class="w-full border-grey-light" v-model="snowReportDataSelected.open">
              <vs-select-item :key="status.value" :value="status.value" :text="status.label" v-for="status in resortStatus"/>
          </vs-select>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.avalancherisk") }}</LabelForm> 
          <vs-select class="w-full border-grey-light" v-model="snowReportDataSelected.avalanche_risk">
              <vs-select-item :key="risk.value" :value="risk.value" :text="risk.label" v-for="risk in avalancheRisk"/>
          </vs-select>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.openslopes") }}</LabelForm> 
          <vs-input type="number" min="0" class="w-full border-grey-light" v-model="snowReportDataSelected.open_slopes" @blur="checkMaxValueNumber('open_slopes', snowReportDataSelected.open_slopes, 100)" />
          <!-- <p class="text-grey">%</p> -->
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.openlifts") }}</LabelForm> 
          <vs-input type="number" min="0" class="w-full border-grey-light" v-model="snowReportDataSelected.open_lifts" @blur="checkMaxValueNumber('open_lifts', snowReportDataSelected.open_slopes, 100)" />
          <!-- <p class="text-grey">%</p> -->
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.distance") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.skiable_km" />
          <p class="text-grey">{{ 'distance' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.mintemperature") }}</LabelForm> 
          <vs-input type="number" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.temp_min" />
          <p class="text-grey">{{ 'temperature' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.maxtemperature") }}</LabelForm> 
          <vs-input type="number" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.temp_max" />
          <p class="text-grey">{{ 'temperature' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.windspeed") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.wind_speed" @blur="checkMinValueNumber('wind_speed', snowReportDataSelected.wind_speed, 0)"/>
          <p class="text-grey">{{ 'speed' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.minsnowdepth") }}</LabelForm>
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.min_snow" @blur="checkMinValueNumber('min_snow', snowReportDataSelected.min_snow, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.maxsnowdepth") }}</LabelForm>
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.max_snow" @blur="checkMinValueNumber('max_snow', snowReportDataSelected.max_snow, snowReportDataSelected.min_snow)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.midmountainsnowdepth") }}</LabelForm>
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.mid_snow" @blur="checkMinValueNumber('mid_snow', snowReportDataSelected.mid_snow, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfall24h") }}</LabelForm>
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.snowfall_24" @blur="checkMinValueNumber('snowfall_24', snowReportDataSelected.snowfall_24, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfall48h") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.snowfall_48" @blur="checkMinValueNumber('snowfall_48', snowReportDataSelected.snowfall_48, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfall72h") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.snowfall_72" @blur="checkMinValueNumber('snowfall_72', snowReportDataSelected.snowfall_72, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfallseason") }}</LabelForm>
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.snowfall_season" @blur="checkMinValueNumber('snowfall_season', snowReportDataSelected.snowfall_season, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfallovernight") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.snowfall_overnight" @blur="checkMinValueNumber('snowfall_overnight', snowReportDataSelected.snowfall_overnight, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.snowfallcondition") }}</LabelForm>
          <vs-select v-if="isDataLoaded" class="w-full border-grey-light" v-model="snowReportDataSelected.snow_type.value">
              <vs-select-item :key="snowType.value" :value="snowType.value" :text="snowType.label" v-for="snowType in snowTypes"/>
          </vs-select>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.basesnow") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.base_snow" @blur="checkMinValueNumber('base_snow', snowReportDataSelected.base_snow, 0)"/>
          <p class="text-grey">{{ 'height' | unit_system_converter(referenceSystem) }}</p>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.newsnow") }}</LabelForm> 
          <vs-input type="number" min="0" step="0.01" class="w-full border-grey-light" v-model="snowReportDataSelected.new_snow" @blur="checkMinValueNumber('new_snow', snowReportDataSelected.new_snow, 0)"/>
          <p class="text-grey">{{ 'depth' | unit_system_converter(referenceSystem) }}</p>
        </div>
        <div class="w-full vx-col xl:w-1/2">
        <LabelForm>{{ $t("snowreport.label.lastsnowfall") }}</LabelForm> 
        <b-form-datepicker  class="w-full rounded-lg"
          today-button
          reset-button
          close-button
          v-model="snowReportDataSelected.last_snowfall" 
          placeholder="" 
          name="last_snowfall" 
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
          :locale="applicationLanguage"/>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.seasonopeningdate") }}</LabelForm> 
          <b-form-datepicker  class="w-full rounded-lg"
            reset-button
            close-button
            v-model="snowReportDataSelected.season_opening_date" 
            placeholder="" 
            name="seasonopeningdate" 
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
            :locale="applicationLanguage"/>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.seasonclosingdate") }}</LabelForm> 
          <b-form-datepicker  class="w-full rounded-lg"
            reset-button
            close-button
            v-model="snowReportDataSelected.season_closing_date" 
            placeholder="" 
            name="seasonclosingdate" 
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
            :locale="applicationLanguage"/>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.conditionssummary") }}</LabelForm> 
          <textarea class="w-full h-32 rounded resize-none border-grey-light" v-model="snowReportDataSelected.conditions_summary" ></textarea>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.anouncement") }}</LabelForm> 
          <textarea class="w-full h-32 rounded resize-none border-grey-light" v-model="snowReportDataSelected.anouncement" ></textarea>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.resortsummary") }}</LabelForm> 
          <textarea class="w-full h-32 rounded resize-none border-grey-light" v-model="snowReportDataSelected.resortsummary" ></textarea>
        </div>
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.weatherreport") }}</LabelForm> 
          <textarea class="w-full h-32 rounded resize-none border-grey-light" v-model="snowReportDataSelected.weather_report" ></textarea>
        </div>
      </div>

      <div class="w-full vx-row">
        <div class="w-full vx-col xl:w-1/2">
          <LabelForm>{{ $t("snowreport.label.openinghours") }}</LabelForm> 
          <textarea class="w-full h-32 rounded resize-none border-grey-light" v-model="snowReportDataSelected.opening_hours" ></textarea>
        </div>
      </div>

      <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
          <ul class="list-none">
              <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
          </ul>
      </b-alert>

      <div class="mt-10">
          <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveSnowReport()">{{ $t("snowreport.status.save") }}</vs-button>
      </div>
    </div>
</template>
<script>
import {DateTime} from 'luxon';
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import SnowReportService from '@/modules/Resorts/Services/SnowReportService'
import {getResortStatus, getAvalancheRisk, getSnowTypes, getFormatInSelectSnowTypes, findTypeOfUnit} from '@/modules/Resorts/Helpers/SnowReportHelper'
import LabelForm from '@/modules/Shared/Components/form/LabelForm' 
import {mapGetters} from 'vuex';
export default {
    name: 'snowreport-form',
    components: {
        LabelForm
    },
    data: () => ({
      snowReportDataSelected:{},
      dateReport:'',
      newSnowReport: false,
      resortStatus: [],
      avalancheRisk: [],
      snowTypes: [],

      dataToUpdate:'',
      errorsOnSave:[]
    }),
    props: {
      referenceSystem: { type: String,  default: 'metric'},
      timezone: { type: String,  required: true},
    },
    computed: {
      ...mapGetters( ['resortUuid','applicationLanguage']),
        isDataLoaded() {
          return  Object.keys(this.snowReportDataSelected).length > 0
        }
    },

    async created() {
      //DE MOMENT NOMÉS HI HA UN SNOWREPORT PER ESTACIÓ, PERÒ EN UN FUTUR N?HI PODRÀ HAVER + d'1

      const snowReport = await unifyPaginatedResponse(this.getSnowReports);
      if(snowReport.length >0){
        this.snowReportDataSelected = this.convertUnits(snowReport[0]);
      } else {
        this.newSnowReport = true;
      }
      

      this.snowReportDataSelected.snow_type = getFormatInSelectSnowTypes(this.snowReportDataSelected.snow_type);

      this.dateReport = this.getDateReport();

      this.snowReportDataSelected.last_snowfall = this.snowReportDataSelected.last_snowfall?DateTime.fromSeconds(this.snowReportDataSelected.last_snowfall).toJSDate():'';
      
      this.snowReportDataSelected.season_opening_date = this.snowReportDataSelected.season_opening_date?DateTime.fromSeconds(this.snowReportDataSelected.season_opening_date).toJSDate():'';
      
      this.snowReportDataSelected.season_closing_date = this.snowReportDataSelected.season_closing_date?DateTime.fromSeconds(this.snowReportDataSelected.season_closing_date).toJSDate():'';
      
      this.resortStatus = getResortStatus();

      this.avalancheRisk = getAvalancheRisk();

      this.snowTypes = getSnowTypes();

    },

    methods: {
      checkMaxValueNumber(model, value, maxValue) {
        if(value > maxValue) {
          this.snowReportDataSelected[model] = maxValue
        }
      },
      checkMinValueNumber(model, value, minValue) {
        if(value < minValue) {
          this.snowReportDataSelected[model] = minValue
        }
      },
      async getSnowReports() {
        return await SnowReportService.getSnowReport(this.resortUuid)
      },

      getDateReport() {
        if(this.snowReportDataSelected.updated){
          const date = DateTime.fromSeconds(this.snowReportDataSelected.updated).setZone(this.timezone).setLocale(this.applicationLanguage).toFormat('dd-LL-yyyy HH:mm:ss')
          return `${date} (${this.timezone})`
        } else {
          return ''
        }
      },

      convertUnits(snowReport,referenceSystem_destination = 'imperial') {
        return Object.keys(snowReport).reduce(
          (attrs, key) => ({
            ...attrs,
            [key]: this.referenceSystem === 'imperial' ? this.$options.filters.value_system_converter(snowReport[key],referenceSystem_destination,findTypeOfUnit(key)):snowReport[key],
          }),
          {}
        );
      },

      async saveSnowReport()
      {
        let response;
        this.dataToUpdate = this.convertUnits({...this.snowReportDataSelected},'metric')
        if (this.dataToUpdate.last_snowfall != '') {
          this.dataToUpdate.last_snowfall = DateTime.fromJSDate(new Date(this.dataToUpdate.last_snowfall)).toFormat('yyyy-LL-dd')
        }

        this.dataToUpdate.open = this.snowReportDataSelected.open === 'true' ? 1 : 0

         for (var propName in this.dataToUpdate) {
           if( this.dataToUpdate[propName] && typeof this.dataToUpdate[propName] === 'object' ) {
             this.dataToUpdate[propName] = this.dataToUpdate[propName].value
           } else if (this.dataToUpdate[propName] === undefined) {
            delete this.dataToUpdate[propName];
          } 
        }

        if(!this.hasErrors()) {
          const now = new Date()
          this.dataToUpdate.updated_timestamp = parseInt(now.getTime() / 1000);
          this.dataToUpdate.updated = now.toJSON().slice(0,19).replace(/T/g,' ');
          if(this.newSnowReport) {
            response = await SnowReportService.createSnowReport(this.resortUuid, this.dataToUpdate)
          } else {
            response = await SnowReportService.updateSnowReport(this.resortUuid, this.dataToUpdate)
          }
        }

        if (response.code == 200) {
            this.$vs.notify( { text: this.$t("snowreport.message.successfully"), color: 'success', position:'top-right' } )
            setTimeout(() => {
                this.$router.push({name: 'snow-report'});
            }, 1000)
        } else {
            this.$vs.notify( { text: this.$t("snowreport.message.error"), color: 'danger', position:'top-right'})
        }
      },
      hasErrors() {
        this.errorsOnSave = [];
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.open_slopes, 'Open slopes');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.open_lifts, 'Open lifts');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.skiable_km, 'Skiable distance');
        this.checkIfExistsAndIsNumber(this.dataToUpdate.temp_min, 'Min. temperature');
        this.checkIfExistsAndIsNumber(this.dataToUpdate.temp_max, 'Max. temperature');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.wind_speed, 'Wind speed');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.min_snow, 'Min. snow depth');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.max_snow, 'Max. snow depth');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.mid_snow, 'Mid. mountain snow depth');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.snowfall_24, 'Last 24h snowfall');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.snowfall_48, 'Last 48h snowfall');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.snowfall_72, 'Last 72h snowfall');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.snowfall_season, 'Snowfall season');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.snowfall_overnight, 'Snowfall overnight');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.base_snow, 'Base snow');
        this.checkIfExistsAndIsPositiveNumber(this.dataToUpdate.new_snow, 'New snow');

        return this.errorsOnSave.length !== 0;
      },
      
      checkIfExistsAndIsNumber(field)
      {
        if (field && isNaN(field)) {
            let errorMessage = this.$t("isnotanumber", { field:field })
            this.errorsOnSave = [...this.errorsOnSave, errorMessage]
            return false;
        }
        return true;
      },

      checkIfExistsAndIsPositiveNumber(field)
      {
        if (this.checkIfExistsAndIsNumber(field)) {
          if (field && !isNaN(field) && field < 0) {
              let errorMessage = this.$t("isnotanumber", { field:field })
              this.errorsOnSave = [...this.errorsOnSave, errorMessage]
          }
        }
      }
    }
}
</script>