<template>
<div class="vx-row w-full ">
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('snowreport.report.maintitle')" active class="mb-10">
        <SnowReportForm :referenceSystem="resortInfo.reference_system" :timezone="resortInfo.timezone" />
      </b-tab>
      <b-tab :title="$t('snowreport.report.slopestitle')">
        <SlopeFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.liftstitle')">
        <LiftFormDataTable/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.bikeparkslopestitle')">
        <BikeparkFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.linkstitle')">
        <LinkFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.itinerariestitle')">
        <ItineraryFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.activitiestitle')">
        <ActivityFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.parkingtitle')">
        <ParkingFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
      <b-tab :title="$t('snowreport.report.roadstitle')">
        <RoadAccesFormDataTable :facilityDifficultyReferenceSystem="resortInfo.slope_difficulty_referencesystem"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SnowReportForm from '@/modules/Resorts/Pages/snowreport/forms/SnowReportForm'
import LinkFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/LinkFormDataTable'
import SlopeFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/SlopeFormDataTable'
import LiftFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/LiftFormDataTable'
import BikeparkFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/BikeparkFormDataTable'
import ItineraryFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/ItineraryFormDataTable'
import ActivityFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/ActivityFormDataTable'
import ParkingFormDataTable from '@/modules/Resorts/Pages/snowreport/forms/ParkingFormDataTable.vue'
import RoadAccesFormDataTable from './forms/RoadAccesFormDataTable.vue'
import {mapGetters} from 'vuex';
export default {
  name: 'snowreport',
  components: {
    SnowReportForm,
    LinkFormDataTable,
    SlopeFormDataTable,
    LiftFormDataTable,
    BikeparkFormDataTable,
    ItineraryFormDataTable,
    ActivityFormDataTable,
    ParkingFormDataTable,
    RoadAccesFormDataTable
},
  data: () => ({
    resortInfo: {},
    slopes: [],
    lifts: [],
    bikeparkSlopes: [],
  }),
  computed: {
    ...mapGetters( ['resortUuid']),
    ...mapGetters( 'resorts',['resortInfoByUuid'])
  },
  async created() {
    this.resortInfo = this.resortInfoByUuid(this.resortUuid);
  }
}
</script>